<template>
  <div class="tw-rounded mb-3 billing-help-address tw-p-4 tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-400">
    <h3 class="tw-font-medium billing-help-address-title tw-text-base">
      {{ $t('billing.help.how-it-works.invoice_to') }}
    </h3>
    <address
      class="billing-help-address-content tw-mb-0"
    >
      Chronotruck<br>
      {{ address }}<br>
      {{ postalCode }}, {{ city }}<br>
      {{ vat }}<br>
    </address>
  </div>
</template>

<script>
  import chronoinfos, { location } from '@/../config/chronoinfos'

  /**
   * @module component - billingHelpAddress
   */
  export default {
    name: 'BillingHelpAddress',
    data () {
      return {
        address: location.address,
        postalCode: location.postalCode,
        city: location.city,
        vat: chronoinfos.VAT
      }
    }
  }
</script>
