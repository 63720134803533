<template>
  <div class="tw-flex billing-help-email tw-rounded tw-py-2 tw-px-3 tw-bg-gray-100 tw-border tw-border-solid tw-border-gray-400">
    <UiLink
      :href="`mailto:${email}`"
      class="billing-help-email-content"
    >
      {{ email }}
    </UiLink>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component - billingHelpEmail
   */
  export default defineComponent({
    name: 'BillingHelpEmail',
    setup () {
      return {
        email: chronoinfos.emails.billing
      }
    }
  })
</script>
