<template>
  <div class="invoice-detail tw-flex tw-flex-col tw-flex-1">
    <invoice-detail-header
      class="flex-fixed"
      :invoice="getCurrentInvoice"
    />
    <div
      class="invoice-detail-content tw-flex-1 tw-flex tw-flex-col"
    >
      <div
        v-if="!$wait.is('loading invoice') && !$wait.is('loading invoice billing') && getCurrentInvoice && !$err.has('no invoice')"
        class="invoice-detail-inner-content tw-p-4"
        data-test="invoice-detail-inner-content"
      >
        <pricing-invoice
          :invoice="getCurrentInvoice"
          :currency="getCurrentInvoice.currency"
        />
        <invoice-detail-invoices
          v-if="isUserShipper
            ? (getCurrentInvoice.shipment && getCurrentInvoice.shipment.billing)
            : (getCurrentInvoice.mission && getCurrentInvoice.mission.billing)"
        />

        <template
          v-if="getCurrentInvoice.shipment || getCurrentInvoice.mission"
        >
          <component
            :is="invoiceDetailTotalComponent"
          />

          <template
            v-if="isUserShipper"
          >
            <ui-button
              :to="{
                name: 'Shipment',
                params: {
                  uuid: getCurrentInvoice.shipment.uuid
                }
              }"
              :rounded="false"
              class="mt-3 tw-w-full"
              variant="info"
              outline
              data-test="view-shipment"
            >
              {{ $t('billing.buttons.view_shipment') }}
            </ui-button>
          </template>

          <template v-else-if="isMissionOwner">
            <ui-button
              :to="{
                name: 'Mission',
                params: {
                  uuid: getCurrentInvoice.mission.uuid
                }
              }"
              class="mt-3 tw-w-full"
              variant="info"
              data-test="view-mission"
            >
              {{ $t('billing.buttons.view_mission') }}
            </ui-button>
          </template>
        </template>
      </div>

      <ctk-loading-layer v-if="$wait.is('loading invoice')">
        {{ $t('billing.loading_invoice_detail') }}
      </ctk-loading-layer>

      <div
        v-if="$err.has('no invoice') && !getCurrentInvoice && !$wait.is('loading invoice')"
        class="tw-flex-1 invoice-detail__error"
      >
        <ctk-empty-state
          :title="$t('billing.title.no_invoice')"
          sidebar
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import CtkEmptyState from '@/components/CtkEmptyState/index.vue'
  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import InvoiceDetailHeader from './_subs/InvoiceDetailHeader/index.vue'
  import InvoiceDetailInvoices from './_subs/InvoiceDetailInvoices/index.vue'
  import InvoiceDetailTotal from './_subs/InvoiceDetailTotal/index.vue'
  import InvoiceDetailTotalMission from './_subs/InvoiceDetailTotal/_subs/InvoiceDetailTotalMission/index.vue'
  import InvoiceDetailTotalShipment from './_subs/InvoiceDetailTotal/_subs/InvoiceDetailTotalShipment/index.vue'
  import PricingInvoice from '@/components/CtkPricing/_subs/PricingInvoice/index.vue'

  /**
   * @module component - invoiceDetail
   */
  export default defineComponent({
    name: 'InvoiceDetail',
    components: {
      CtkEmptyState,
      CtkLoadingLayer,
      InvoiceDetailHeader,
      InvoiceDetailInvoices,
      InvoiceDetailTotal,
      InvoiceDetailTotalMission,
      InvoiceDetailTotalShipment,
      PricingInvoice
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getUserInfos'
      ]),
      ...mapGetters('billing', [
        'getCurrentInvoice'
      ]),
      /**
       * Returns the component name for the total component,
       * according to the user type.
       * @function invoiceDetailTotalComponent
       * @returns {string} component
       */
      invoiceDetailTotalComponent () {
        return this.isUserShipper
          ? 'InvoiceDetailTotalShipment'
          : 'InvoiceDetailTotalMission'
      },
      isMissionOwner () {
        return this.getCurrentInvoice.mission.owner.uuid === this.getUserInfos.uuid
      }
    }
  })
</script>

<style lang="scss" scoped>

  #app:has(.ctk-impersonate-nav-bar) .invoice-detail-inner-content {
    padding-bottom: 60px;
  }

  .invoice-detail {
    background-color: #F4F5F5;

    &-content {
      position: relative;
      overflow-y: auto;
    }
  }

</style>
