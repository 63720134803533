<template>
  <pricing-total
    v-if="getCurrentPaymentInvoices"
    :label="$t('billing.payment_total', {
      count: $n(getCurrentPaymentInvoices.length)
    })"
    :primary-value="$t('price_incl_tax', {
      price: total
    })"
    class="payment-detail-total"
  >
    <slot />
  </pricing-total>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PricingTotal from '@/components/CtkPricing/_subs/PricingTotal/index.vue'

  /**
   * @module component - PaymentDetailTotal
   * @param {string} total - Formatted total to be displayed
   */
  export default {
    name: 'PaymentDetailTotal',
    components: {
      PricingTotal
    },
    props: {
      total: {
        type: String,
        required: true
      }
    },
    computed: {
      ...mapGetters('billing', [
        'getCurrentPaymentInvoices'
      ])
    }
  }
</script>
