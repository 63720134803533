<template>
  <aside class="p-4 billing-help">
    <img
      src="@/assets/img/icons/info_billing.png"
      alt=""
      width="55"
      class="billing-help-icon"
    >
    <div class="p-4 tw-rounded billing-help-content">
      <billing-help-section
        :title="$t('billing.help.how-it-works.title')"
      >
        <div class="billing-help-how-it-works-content">
          <p>
            {{ $t('billing.help.how-it-works.broker') }}
          </p>
          <i18n
            path="billing.help.how-it-works.send_invoices_chronotruck"
            tag="p"
          >
            <template #invoices>
              <span
                v-text="$tc('billing.invoice_type.mission_main', 2)"
                class="tw-font-medium tw-text-blue-500"
              />
            </template>
            <!-- TODO: Typo ? -->
            <template #additionnal_invoices>
              <span
                v-text="$tc('billing.invoice_type.additionnal', 2)"
                class="tw-font-medium tw-text-red-500"
              />
            </template>
            <template #credit_invoices>
              <span
                v-text="$tc('billing.invoice_type.credit', 2)"
                class="tw-font-medium tw-text-red-500"
              />
            </template>
          </i18n>
          <p class="tw-rounded p-2 billing-help-content--danger">
            <ui-ctk-icon
              name="warning"
              data-test="icon"
            /> {{ $t('billing.help.how-it-works.join_pof') }}
          </p>
        </div>
        <billing-help-address />

        <p class="billing-help-how-it-works-payment-delay">
          {{ $t('billing.help.how-it-works.payment_delay') }}
        </p>
      </billing-help-section>

      <billing-help-section
        :title="$t('billing.help.invoices.title')"
      >
        <billing-help-email />
      </billing-help-section>

      <billing-help-section
        :title="$t('billing.help.question.title')"
      >
        <i18n
          path="billing.help.question.content"
          tag="p"
          class="billing-help-question-content"
        >
          <template #email>
            <UiLink
              :href="`mailto:${email}`"
              v-text="email"
            />
          </template>
        </i18n>
      </billing-help-section>
    </div>
  </aside>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import BillingHelpAddress from './_subs/BillingHelpAddress/index.vue'
  import BillingHelpSection from './_subs/BillingHelpSection/index.vue'
  import BillingHelpEmail from './_subs/BillingHelpEmail/index.vue'

  import chronoinfos from '@/../config/chronoinfos'

  /**
   * @module component billingHelp
   */
  export default defineComponent({
    name: 'BillingHelp',
    components: {
      BillingHelpAddress,
      BillingHelpSection,
      BillingHelpEmail
    },
    data () {
      return {
        email: chronoinfos.emails.billing
      }
    }
  })
</script>

<style lang="scss" scoped>

  .billing-help {
    position: relative;
    max-width: 400px;
    overflow: auto;

    &-icon {
      position: absolute;
      top: 8px;
      left: 8px;
    }

    &-content {
      background-color: white;
      border: 1px solid #E5E5E5;
      padding-top: 48px !important;

      &--danger {
        background-color: #E9D2D0;

        .ctk-font {
          font-size: 24px;
          color: darken($pink, 15%);
          vertical-align: middle;
          height: 1px;
          float: left;
        }
      }
    }

    .tw-text-red-500 {
      color: $pink !important;
    }
  }

</style>
